import io from 'socket.io-client'

import React, { createContext, FC, useContext, useEffect, useState } from 'react'

type SocketContextType = {
	activeEvents: string[]
	markEventDone: (event: string) => void
}

const SocketContext = createContext<SocketContextType>({
	activeEvents: [],
	markEventDone: () => {},
})

export const getSocketUrl = () => {
	const apiUrl = process.env.GATSBY_API_URL as string
	console.log('apiUrl', process.env.NODE_ENV, apiUrl)
	return apiUrl.replace('https', 'wss')
}

export const socket = io(getSocketUrl())

interface SocketProviderProps {
	children: React.ReactNode
}

const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
	const [activeEvents, setEvents] = useState<string[]>([])

	const markEventDone = (event: string) => {
		setEvents(events => events.filter(ev => ev !== event))
	}

	const value = { activeEvents, markEventDone }

	useEffect(() => {
		socket.on('new_info', () => setEvents(activeEvents => [...activeEvents, 'new_info']))
	}, [])

	return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
}

export const useSocketEvents = (): SocketContextType => {
	const context = useContext(SocketContext)
	return context
}

export default SocketProvider
